
import {defineComponent} from "vue";
import {getCSSVariableValue} from "@/assets/ts/_utils";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import ApiService from "@/core/services/ApiService";
import {Api} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "Widget1",
  components: {
    Dropdown1,
  },
  props: {
    widgetClasses: {type: String, default: ""},
  },
  setup() {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const color1 = getCSSVariableValue("--bs-warning");
    const color1Light = getCSSVariableValue("--bs-light-warning");

    const color2 = getCSSVariableValue("--bs-success");
    const color2Light = getCSSVariableValue("--bs-light-success");

    const color3 = getCSSVariableValue("--bs-primary");
    const color3Light = getCSSVariableValue("--bs-light-primary");

    const options = {
      chart: {
        fontFamily: "inherit",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["30%"],
          endingShape: "rounded",
        },
      },
      legend: {
        show: true,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return "$" + val + " thousands";
          },
        },
      },
      colors: [color1, color2, color3, color1Light, color2Light, color3Light],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };

    const series = [
      {
        name: "Net Profit",
        data: [44, 55, 57, 56, 61, 58],
      },
      {
        name: "Revenue",
        data: [76, 85, 101, 98, 87, 105],
      },
      {
        name: "Revenue 1",
        data: [76, 85, 101, 98, 87, 105],
      },
      {
        name: "Revenue2",
        data: [76, 85, 101, 98, 87, 105],
      },
    ];

    return {
      options,
      series,
    };
  },
  data() {
    return {
      graphData: {},
      graphXaxis: [],
      graphReady: false
    }
  },
  methods: {
    getData() {
      ApiService.get(Api.EMP_STATS_ALL).then(response => {
          this.graphData = response.data.Data
          this.graphXaxis =response.data.Xaxis
      });
    }
  }
})
;
